body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "primeTime";
  src: local("primeTime"),
   url("./assets/fonts/primeTime.ttf") format("truetype");
  }
  
.family{
  font-family: "Primetime",Sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.bg-logo {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 160px;
  width: 160px;
  /* background-image: ''; */
}
.fs-4 {
  font-size: calc(1.275rem + .3vw)!important;
}
.cursor-pointer {
  cursor: pointer;
}
.rounded-pill {
  border-radius: 50rem!important;
}
.text-uppercase {
  text-transform: uppercase!important;
}
.fw-bold {
  font-weight: 700!important;
}
.fs-5 {
  font-size: 1.25rem!important;
}
.py-2 {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}
.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}
.lh-sm {
  line-height: 2.0!important;
}
.blob-top {
  position: absolute;
  top: -25px;
  left: 30px;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb),var(--bs-text-opacity))!important;
}

.text-uppercase {
  text-transform: uppercase!important;
}
.mb-3 {
  margin-bottom: 1rem!important;
}

.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}

img, svg {
    vertical-align: middle;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}
body {
  background: #212529 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}
h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}
body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
.auth-wrapper .form-control:focus {
  border-color: #212529;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #212529;
}
.navbar {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}

.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar a:hover {
  background: #ddd;
  color: black;
}